export const EnableLogs = false
// Test Urls starts //

/* export const serverUrl = 'https://s20q1.mycw.in/cw-retail/graphql'
export const genericUrl ='https://s20q1.mycw.in/back-office-2.0/graphql'
export const tokenUrl = 'https://s20q1.mycw.in/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://s20q1.mycw.in:8443/' */

// Test Urls ends //

// Dev server Urls starts //

/* export const serverUrl = 'https://erps-test.mycw.in/cw-retail/graphql'
export const genericUrl ='https://erps-test.mycw.in/back-office-2.0/graphql'
export const tokenUrl = 'https://identity-test.mycw.in/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://erps-test.mycw.in:8443/' */

// Dev server Urls ends //

// Pre prod Urls starts 

/* export const serverUrl = 'https://s360app.mycw.in/cw-retail/graphql'
export const genericUrl ='https://sapp.mycw.in/services/graphql'
export const tokenUrl ='https://s360app.mycw.in/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://sapp.mycw.in:8443/' */

// export const serverUrl = 'https://s360app.mycw.in/cw-retail/graphql'
// export const genericUrl ='https://sapp.mycw.in/services/graphql'
// export const tokenUrl ='https://s360app.mycw.in/cw-identity/oauth/token'
// export const fileDownloadUrl = 'https://services.plg1.cwsuite.com:8444/'


// https://services.plg1.cwsuite.com/core        -- back-office
// https://services.plg1.cwsuite.com/scheduler    -- quartz-scheduler
// https://services.plg1.cwsuite.com/retail    -- retail
// https://services.plg1.cwsuite.com:8444        -- Download URL
// https://services-noton.plg1.cwsuite.com  

// Pre prod Urls ends

// demo server Urls starts
/* export const serverUrl = 'https://s2demos.cw.solutions/cw-retail/graphql'
export const genericUrl ='https://sdemos.cw.solutions/back-office-2.0/graphql'
export const tokenUrl =' https://identity-demos.cw.solutions/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://sdemos.cw.solutions:8443' */

// ofc test server Urls starts
/* export const serverUrl = 'https://sofc-test-analytics.cw.solutions/cw-retail/graphql'
export const genericUrl ='https://sofc-test-analytics.mycw.in/back-office-2.0/graphql'
export const tokenUrl = 'https://auth-ofc-test-analytics.mycw.in/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://sofc-test-analytics.mycw.in:8443/'
 */

// ofc server Urls starts
/* export const serverUrl = 'https://sofc-test-analytics.cw.solutions/cw-retail/graphql'
export const genericUrl ='https://sofc-test-analytics.mycw.in/back-office-2.0/graphql'
export const tokenUrl = 'https://auth-ofc-test-analytics.mycw.in/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://sofc-test-analytics.mycw.in:8443/' */

// OFC-Live server Urls starts //
/* export const serverUrl = ' '
export const genericUrl ='https://sofc01.cw.solutions/back-office-2.0/graphql'
export const tokenUrl = ' https://sauth.cw.solutions/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://sofc01.cw.solutions:8443/' */
// OFC-Live server Urls ends //


// ofc latest server Urls starts
/* export const URL = ' '
export const GENERICURL ='https://sofc02.cw.solutions/back-office-2.0/graphql'
export const Auth = ' https://sauth1.cw.solutions/cw-identity/oauth/token'
export const FILEDOWNLOADURL = 'https://sofc01.cw.solutions:8443/' */


export const UserId = '100'
// demo server Urls ends

/* export const serverUrl = 'https://s360app.mycw.in/cw-retail/graphql'
export const genericUrl ='https://sone.mycw.in/back-office-2.0/graphql'
export const tokenUrl ='https://sone.mycw.in/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://sapp.mycw.in:8443/' */

// cw one server starts
export const serverUrl = 'https://s360one.mycw.in/cw-retail/graphql'
export const genericUrl ='https://sone.mycw.in/core/graphql'
export const tokenUrl ='https://sone.mycw.in/cw-identity/oauth/token'
export const fileDownloadUrl = 'https://sone.mycw.in:8444/'
export const DOWN_lOAD_TYPE = "S3"   //S3,SSH
// cw one server ends

// cw exceloid server starts
//export const serverUrl = 'https://s360exceloid.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://sexceloid.mycw.in/core/graphql'
//export const tokenUrl ='https://sexceloid.mycw.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://sexceloid.mycw.in:8445/'
//export const DOWN_lOAD_TYPE = "S3"   //S3,SSH
// cw exceloid server ends


// cw opt test  Urls starts

//export const serverUrl = 'https://opt-tserv03.exceloid.in/cw-retail/graphql'
//export const genericUrl ='https://opt-tserv03.exceloid.in/back-office-2.0/graphql'
//export const tokenUrl ='https://opt-tserv03.exceloid.in/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://opt-tserv03.exceloid.in:8445/'

// cw opt  test Urls ends


//export const serverUrl = 'https://brand-hub-services.cwcloud.in/cw-retail/graphql'
//export const genericUrl ='https://brand-hub-services.cwcloud.in/cw-core/graphql'
//export const tokenUrl ='https://brand-hub-services.cwcloud.in/cw-identity/oauth/token'
//export const fileDownloadUrl = ''
//export const DOWN_lOAD_TYPE = "S3"   //S3,SSH

// cw opt Urls starts
//export const serverUrl = 'https://opt-retail-services.mycw.in/cw-retail/graphql'
//export const genericUrl ='https://opt-services.mycw.in/back-office-2.0/graphql'
//export const serverUrl = 'https://opt-tserv03.exceloid.in/cw-retail/graphql'
//export const genericUrl ='https://sofc-ksa.cw.solutions/back-office/graphql'
//export const genericUrl ='https://opt-services.mycw.in/back-office/graphql'
//export const tokenUrl ='https://opt-services.mycw.in/cw-identity/oauth/token'
//export const tokenUrl ='https://sofc-ksa.cw.solutions/cw-identity/oauth/token'
//export const fileDownloadUrl = 'https://opt-services.mycw.in:8443/'
// cw opt Urls ends


