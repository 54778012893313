import React, { useEffect, useState } from "react";
import { Modal, Menu, message, Drawer, Collapse, List, Card, Dropdown, Space } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import { ReactComponent as CloseX } from "./closeX.svg";
import "./style.css";
import { useGlobalContext } from "../../lib/storage";
import mobileMenuLogo from "../../assets/images/mobMenuLogo.svg";
import appIcon from "../../assets/images/appIconMobile.svg";

export default function MobileMenu(props) {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const history = useHistory();
  const { SubMenu } = Menu;
  const menuList = globalStore.sideMenuData;
  const [menuData, setMenuData] = useState([]);

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  useEffect(() => {
    // getCwLogos();
    // getFaviouritesMenus();
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
        //
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }
    // setBunitId(globalStore.userData.bunit_id);
    // setRoleId(globalStore.userData.role_id);
  }, []);
  // let subMenuItem = JSON.parse(localStorage.getItem("subMenuItem"))
  let subMenuData = JSON.parse(localStorage.getItem("subMenuData"));
  const [subMenu, setSubMenu] = useState(JSON.parse(localStorage.getItem("subMenuData")));
  const { mobMenu, setMobMenu, setSelectedMainMenuItem } = props;
  const [openKeys, setOpenKeys] = useState(subMenu.map((menu) => menu.key));
  const [open, setOpen] = useState(false);

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    setOpenKeys(subMenu.map((menu) => menu.key));
  }, [subMenu]);

  const navigateToFirstChild = (data) => {
    let menuData = JSON.parse(localStorage.getItem("sideMenuData"));
    menuData.map((menu, index) => {
      if (menu.key === data.key1) {
        console.log(menu.children);
        localStorage.setItem("selectecMianMenuItem", JSON.stringify(data.key1));
        localStorage.setItem("subMenuData", JSON.stringify(menu.children));
        setSubMenu(menu.children);
        setSelectedMainMenuItem(menu.children);
      }
    });
    localStorage.setItem("selectecMianMenuItem", JSON.stringify(data.name));
    if (data.children === undefined || data.children === null) {
      localStorage.removeItem("lowerSubMenu");
      localStorage.setItem("subMenuItem", JSON.stringify(data.key));
      // setMenuToggle(true)
      // setFullMenuToggle(true)
      if (data.type === "Report") {
        history.push(`/reports/report/${data.id}`);
      } else if (data.type === "Dashboard") {
        localStorage.setItem("windowType", "Dashboard");
        history.push(`/analytics/dashboard/${data.id}`);
      } else if (data.type === "Generic") {
        history.push(`/window/list/${data.id}`);
      } else if (data.type === "Custom") {
        history.push(`/others/window/${data.id}`);
      }
    } else {
      localStorage.setItem("subMenuItem", JSON.stringify(data.key));
      localStorage.setItem("lowerSubMenu", JSON.stringify(data.children));
      localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.children[0].key));
      if (data.children[0].type === "Report") {
        localStorage.setItem("windowType", "Report");
        history.push(`/reports/report/${data.children[0].id}`);
      } else if (data.children[0].type === "Dashboard") {
        localStorage.setItem("windowType", "Dashboard");
        history.push(`/analytics/dashboard/${data.children[0].id}`);
      } else if (data.children[0].type === "Generic") {
        history.push(`/window/list/${data.children[0].id}`);
      } else if (data.children[0].type === "Custom") {
        history.push(`/others/window/${data.children[0].id}`);
      }
    }
  };

  const subMenuNavigate = (data) => {
    if (data.children === undefined || data.children === null) {
      localStorage.setItem("subMenuItem", JSON.stringify(data.key));
      localStorage.setItem("lowerSubMenu", null);
      // setMenuToggle(true)
      // setFullMenuToggle(true)
      if (data.type === "Report") {
        history.push(`/reports/report/${data.id}`);
      } else if (data.type === "Dashboard") {
        history.push(`/analytics/dashboard/${data.id}`);
      } else if (data.type === "Generic") {
        history.push(`/window/list/${data.id}`);
      } else if (data.type === "Custom") {
        history.push(`/others/window/${data.id}`);
      }
    } else {
      localStorage.setItem("lowerSubMenu", JSON.stringify(data.children));
      localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.children[0].key));
      localStorage.setItem("subMenuItem", JSON.stringify(data.key));
      if (data.children[0].type === "Report") {
        history.push(`/reports/report/${data.children[0].id}`);
      } else if (data.children[0].type === "Dashboard") {
        history.push(`/analytics/dashboard/${data.children[0].id}`);
      } else if (data.children[0].type === "Generic") {
        history.push(`/window/list/${data.children[0].id}`);
      } else if (data.children[0].type === "Custom") {
        history.push(`/others/window/${data.children[0].id}`);
      }
    }
  };

  const selectMenuToNavigate = (data) => {
    localStorage.setItem("selectedSubMenuTitle", subMenuData[data.index].title);
    localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.key));
    localStorage.setItem("subMenuItem", JSON.stringify(data.title));
    if (data.type === "Report") {
      localStorage.setItem("windowType", "Report");
      history.push(`/reports/report/${data.id}`);
    } else if (data.type === "Dashboard") {
      localStorage.setItem("windowType", "Dashboard");
      history.push(`/analytics/dashboard/${data.id}`);
    } else if (data.type === "Generic") {
      history.push(`/window/list/${data.id}`);
    } else if (data.type === "Custom") {
      history.push(`/others/window/${data.id}`);
    } else {
      message.warning("Not Available");
    }
    setMobMenu(false);
  };
  // let topMenu = JSON.parse(localStorage.getItem("sideMenuData"))
  let mainMenuItem = JSON.parse(localStorage.getItem("selectecMianMenuItem"));
  let lowerSubMenuItem = JSON.parse(localStorage.getItem("lowerSubMenuItem"));
  const content = (
    <Card style={{ width: "60%", padding: "1em" }}>
      <Menu
        selectedKeys={mainMenuItem}
        itemLayout="vertical"
        onClick={(t) => {
          setOpen(!t);
        }}
        style={{ backgroundColor: "", fontFamily: "Inter", width: "fit-content", cursor: "pointer" }}
      >
        {menuData?.map((menu) => {
          return (
            <Menu.Item
              onClick={() =>
                navigateToFirstChild({
                  type: menu.children[0].type,
                  id: menu.children[0].id,
                  key: menu.children[0].key,
                  children: menu.children[0].children,
                  name: menu.title,
                  key1: menu.key,
                })
              }
              key={menu.key}
            >
              {menu.title}
            </Menu.Item>
          );
        })}
      </Menu>
    </Card>
  );

  return (
    <Drawer
      placement="left"
      visible={mobMenu}
      title={
        <>
          <div style={{ backgroundColor: "#232D4D", height: "3em", padding: "0px" }}>
            <img style={{ padding: "15px 0px 0px 15px" }} src={mobileMenuLogo} alt="" />

            <span style={{ float: "right", padding: "12px 12px 0px 0px" }}>
              <Icon onClick={() => setMobMenu(false)} component={CloseX} />
            </span>
          </div>
        </>
      }
      style={{ float: "left", top: 0 }}
      footer={null}
      closable={false}
    >
      <Scrollbars
        style={{
          height: "85vh",
        }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbSize={90}
        renderView={renderView}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
      >
        <span style={{}}>
          <Dropdown trigger={["click"]} overlay={content} open={open}>
            <div style={{ display: "flex", verticalAlign: "center", marginLeft: "1.5em" }}>
              <img src={appIcon} alt="" />
              &nbsp;&nbsp;
              <span style={{ fontFamily: "Inter", color: "#0D1216", fontWeight: 700, cursor: "pointer" }}>{mainMenuItem}</span>
            </div>
          </Dropdown>
        </span>

        <Menu
          style={{ paddingLeft: "20px" }}
          mode="inline"
          className="mobileMenu"
          selectedKeys={lowerSubMenuItem}
          openKeys={openKeys}
          onOpenChange={handleOpenChange} /* openKeys={openKeys} onOpenChange={onOpenChange} */
        >
          {subMenuData.map((menu, index) =>
            menu.children && menu.children.length > 0 ? (
              <SubMenu key={menu.key} title={menu.title} className="sideMenu">
                {menu.children.map((subMenuItem, ind) => (
                  <Menu.Item
                    key={subMenuItem.key}
                    style={{ width: "auto", paddingLeft: "4em" }}
                    onClick={() => selectMenuToNavigate({ type: subMenuItem.type, id: subMenuItem.id, key: subMenuItem.key, title: subMenuItem.title, index: index })}
                    // style={{width:'auto',overflow:'visible',textOverflow:'clip',whiteSpace:'normal',lineHeight:'15px'}}
                  >
                    <span style={{}} title={subMenuItem.title}>
                      {subMenuItem.title}
                    </span>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                key={menu.key}
                style={{ width: "auto", paddingLeft: "0px" }}
                onClick={() => subMenuNavigate({ type: menu.type, id: menu.id, key: menu.key, children: menu.children })}
                // style={{width:'auto',overflow:'visible',textOverflow:'clip',whiteSpace:'normal',lineHeight:'15px'}}
              >
                <span title={menu.title} style={{ fontWeight: 600 }}>
                  {menu.title}
                </span>
              </Menu.Item>
            )
          )}
        </Menu>
      </Scrollbars>
    </Drawer>
  );
}
