import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useGlobalContext } from "../storage";
import { useHistory } from "react-router-dom";

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { globalStore } = useGlobalContext();
  const { authTokens } = globalStore;
  const history = useHistory();
  const myArray = history.location.pathname.split("/")
  const isWindowAtIndex1 = myArray[1] === 'window';
    const lastElement = myArray[myArray.length - 1];
    const is32BitID = lastElement.length === 32 && /^[a-fA-F0-9]+$/.test(lastElement);
  
  if (isWindowAtIndex1 && is32BitID) {
    localStorage.setItem("form","true")
  } else {
    localStorage.removeItem("form")
  }
  return <Route {...rest} render={(props) => (authTokens ? <Layout><Component {...props} /></Layout> : <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />)} />;
};

export default PrivateRoute;
