import React, { useState, Fragment } from "react";
import { Form, Input, Select,DatePicker,Button } from "antd";
import {getSelectorDataFromApi} from "../../../services/generic"
import { useGlobalContext } from "../../../lib/storage";
import moment from 'moment'

const {Option} = Select
const FormField =(props) =>{
  const { globalStore } = useGlobalContext();
  const userPreferences = globalStore.userPreferences;
  const dateFormat = userPreferences.dateFormat
 const {field,form,selectedOption,setSelectedOption,getdraftData,selectedHistoryRecord,getOptionDataToset} = props
 const [searchedValue,setSearchedValue] = useState('')
 const [optionsForSelector,setOptionsForSelector] = useState([])
 let statusFlag = selectedHistoryRecord?.status === "Completed" || selectedHistoryRecord?.status === "Booked" ? true : false

 const getSelectorData = async ()=>{
    const valuesObj = field.apiBuiler.params
    let valuesCopy = { ...valuesObj }
    for (const key in valuesObj) {
        if (valuesObj.hasOwnProperty(key) && valuesObj[key]) {
          if(valuesObj[key] === "null"){
            valuesCopy[key] = "null"
          }
          else if(valuesObj[key].includes("#")){
            valuesCopy[key] = valuesObj[key].replace("#","")
          }else{
          const fieldData = form.getFieldValue(valuesObj[key]);
          valuesCopy[key] = fieldData
          }
        }
      }

    const stringifiedJSON = JSON.stringify(valuesCopy);
    const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
    const response = await getSelectorDataFromApi(field.apiBuiler.id,jsonToSend)
    if(response){
      for (let index = 0; index < response.length; index++) {
        response[index].name = response[index].value ? response[index].value + ' - ' + response[index].name : response[index].name;
      }
      setOptionsForSelector(response === null || response === undefined ? []:response)
    }
 }


const handleDropdownChange = (fie, value, option) => {
  if (value === null || value === undefined) {
    setSelectedOption((prevState) => {
      const newState = { ...prevState };
      delete newState[fie.name];
      return newState;
    });
  } else {
    setSelectedOption((prevState) => ({
      ...prevState,
      [fie.name]: { recordId: value, name: option.children },
    }));
  }

  if (fie.isSearch && value && getOptionDataToset) {
    getOptionDataToset(option.option);
  }
};


const fetchLines = (fiel) =>{
  if(fiel.lineData != null){
    const valuesObj2 = fiel.lineData.apiBuiler.params
    let valuesCopy2 = { ...valuesObj2 }
    for (const key in valuesObj2) {
        if(selectedOption[valuesObj2[key]]){
          valuesCopy2[key] = selectedOption[valuesObj2[key]]?.recordId === undefined ? null : selectedOption[valuesObj2[key]]?.recordId
        }else{
        const fieldData = form.getFieldValue(valuesObj2[key]) === undefined ? null :form.getFieldValue(valuesObj2[key]);
        valuesCopy2[key] = fieldData
        }
      }
    const stringifiedJSON = JSON.stringify(valuesCopy2);
    const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
    if(jsonToSend !== "{}"){
      getdraftData(fiel.lineData.apiBuiler.id,jsonToSend)
    }
  }
}

const handleListChange = (fie,value, option) =>{
  if (value === null || value === undefined) {
    setSelectedOption((prevState) => {
      const newState = { ...prevState };
      delete newState[fie.name];
      return newState;
    });
  } else {
  setSelectedOption((prevState) => ({
    ...prevState,
    [fie.name]: { recordId: value, name: option.children },
  }));
}
}


const getData = async(val,field) =>{
  const valuesObj = field.isSearch?.apiBuiler.params
    let valuesCopy = { ...valuesObj }
    for (const key in valuesObj) {
      if (valuesObj.hasOwnProperty(key) && valuesObj[key]) {        
        if(valuesObj[key].includes("searchVal")){
          valuesCopy[key] = valuesObj[key].replace("searchVal",val)
        }
      }
    }
    const stringifiedJSON = JSON.stringify(valuesCopy);
    const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
    const response = await getSelectorDataFromApi(field.apiBuiler.id,jsonToSend)
    setOptionsForSelector(response === null || response === undefined ? []:response)
}

const debounce = (fn, d) => {
  let timer
  return function() {
    let context = searchedValue,
      args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      getData.apply(context, arguments)
    }, d)
  }
}

const debounceLog = debounce(getData, 700)

const getSearchdata = (val,field) => {
debounceLog(val,field)
}

  return (
    <Fragment>
      {(() => {
        switch (field.type) {
            case "selector":
                return (
                    <Form.Item
                      style={{marginBottom:'8px'}}
                      label={<span style={{fontWeight:400,fontSize:13,fontFamily:"Inter",opacity:0.6}}>{field.title}</span>} 
                      name={field.name}
                      rules={[
                        {
                          required: field.ismandatory === "Y" ? true : false,
                          message: `Please select ${field.title}`,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onFocus={getSelectorData}
                        onSearch={(value) => {
                          if (value && field.isSearch) {
                            getSearchdata(value, field);
                          }
                        }}
                        optionFilterProp="children"
                        allowClear={true}
                        disabled={field.isreadonly === "Y" ? true : false}
                        onChange={(value, option) => handleDropdownChange( field,value, option)}
                        >
                        {optionsForSelector.map((option, index) => (
                          <Option key={`${index}-${option.name}`} option={option} value={option.recordid}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
            case "List" :
              return (
                <Form.Item
                      style={{marginBottom:'8px'}}
                      label={<span style={{fontWeight:400,fontSize:13,fontFamily:"Inter",opacity:0.6}}>{field.title}</span>} 
                      name={field.name}
                      rules={[
                        {
                          required: field.ismandatory === "Y" ? true : false,
                          message: `Please select ${field.title}`,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        allowClear={true}
                        disabled={field.isreadonly === "Y" ? true : false}
                        onChange={(value, option) => handleListChange( field,value, option)}
                        >
                        {field?.ListOptions.map((option, index) => (
                          <Option key={`${index}-${option.name}`} value={option.value}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
              )
            case "string":
                return(
                    <Form.Item
                      style={{marginBottom:'8px'}}
                      label={<span style={{fontWeight:400,fontSize:13,fontFamily:"Inter",opacity:0.6}}>{field.title}</span>} 
                      name={field.name}
                      rules={[
                        {
                          required: field.ismandatory === "Y" ? true : false,
                          message: `Please input ${field.title}`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                )
            case "date":
              return(
                <Form.Item
                style={{marginBottom:'8px'}}
                label={<span style={{fontWeight:400,fontSize:13,fontFamily:"Inter",opacity:0.6}}>{field.title}</span>} 
                name={field.name}
                initialValue={moment()}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please select ${field.title}`,
                  },
                ]}
              >
              <DatePicker style={{ width: "100%" }} format={dateFormat} />
              </Form.Item>
              )
          case "button":
            return(
              <Button disabled={statusFlag} onClick={()=>fetchLines(field)} style={{marginTop:'27px',borderRadius:'4px'}} type="primary" size="small">{field.title}</Button>
            )    
        }
       })()}
    </Fragment>
  )
}


export default FormField