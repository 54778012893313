import { Row ,Col,Select,Button, Checkbox, Switch } from 'antd'
import React,{useState,useRef} from 'react'
import { useReactToPrint } from "react-to-print";
import { useHistory } from 'react-router-dom';
import Exit from "../../../assets/images/exit.svg"
import DownArrow from "../../../assets/images/downArrow.svg"
import Sizer from "../../../assets/images/sizer.svg"

import { DownCircleTwoTone } from '@ant-design/icons'

const DigitalReceipt = () =>{
  let componentRef = useRef(null);

  const {Option} = Select
  const history = useHistory();
  const [selectedPrinterType, setSelectedPrinterType] = useState('A4');
  const handleSizeSelect = (size) => {
    console.log(size)
    setSelectedPrinterType(size);
    // Update other state variables for template preview
  };
  

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:"Invoice",
    // documentTitle: `${employee.name.replace(/\s/g, "-")
    //   }-Payslip`,
    onPrintError: () => alert("there is an error when printing"),
  });

  const goBack = () => {
    history.goBack();
  };
  let suffixIcon;
  suffixIcon = <DownCircleTwoTone />;
  return (
    <div>
        <Row style={{backgroundColor:"white",height:"3.5em",padding:"0.7em",borderBottom:"1px solid #E8E8E8"}}>
          <Col  span ={4}>
            <img onClick={goBack} src={Exit} style={{cursor:"pointer"}} alt='exit'/>
            <span onClick={goBack} style={{ fontFamily:"Inter",fontSize:"14px",fontWeight:400,paddingLeft:"0.5em",cursor:"pointer"}}>Exit</span>
          </Col>
          <Col span ={4}>
           <Select onChange={handleSizeSelect} className='sizeSelect' suffixIcon={suffixIcon} placeholder={<span style={{fontFamily:"Inter",fontSize:"14px",fontWeight:400,paddingLeft:"",color:"black"}}><img style={{ paddingRight:"0.5em"}} src={Sizer} alt='exit'/>Size Options </span>} style={{ width: "100%" ,padding:0,borderRadius:'5px',borderColor:'#d9d9d9',border:'0.5px'}}>
           <Option value="A4">A4 Printer</Option>
          <Option value="Thermal">Thermal Printer</Option> 
           </Select>
          </Col>
          <Col span={12}/>
    
          <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button onClick={handlePrint} style={{ fontFamily: "Roboto", fontWeight: "700", width: "70px", border: '0.1px solid rgba(217, 217, 229,1)', backgroundColor: "#0C173A", color: "white", marginLeft: "4px", marginRight: "4px" }}>
              Save
            </Button>
          </Col>

        </Row>
        <Row style={{height:"100%"}}>
          <Col span={5} style={{backgroundColor:"white",height:"92vh",borderRight:"1px solid #E8E8E8"}}>
             <Row style={{padding:"1em"}}>
              <span style={{fontFamily:"Inter",fontWeight:600,fontSize:"16px",color:"#192228"}}>Settings</span>
             </Row>
             <Select className='sizeSelect' suffixIcon={suffixIcon} placeholder={<span style={{fontFamily:"Inter",fontSize:"14px",fontWeight:400,paddingLeft:"",color:"black"}}>Default Layout </span>} style={{ width: "90%" ,paddingLeft:"1em",borderRadius:'5px',borderColor:'#d9d9d9',border:'0.5px'}}>
                    
           </Select>
           <p style={{ fontFamily:"Inter",fontWeight:600,fontSize:"11px",color:"#192228",padding:"1em 0em 0em 2em"}}>Printig Options</p>
           <Checkbox style={{fontFamily:"Inter",fontWeight:400,fontSize:"13px",paddingLeft:"1em"}}>Activate automatic receipt printing</Checkbox>
           <br/>
           <Checkbox style={{fontFamily:"Inter",fontWeight:400,fontSize:"13px",paddingLeft:"1em"}}>Customize number of receipts</Checkbox>
           <hr style={{opacity:0.1,margin:"1em 0em"}}/>
           <Checkbox style={{fontFamily:"Inter",fontWeight:400,fontSize:"13px",paddingLeft:"1em"}}>Logo</Checkbox> <br/>
           <Checkbox style={{fontFamily:"Inter",fontWeight:400,fontSize:"13px",paddingLeft:"1em"}}>Header</Checkbox> <br/>
           <Checkbox style={{fontFamily:"Inter",fontWeight:400,fontSize:"13px",paddingLeft:"1em"}}>Footer</Checkbox>
           <hr style={{opacity:0.1,margin:"1em 0em"}}/>
           <p style={{ fontFamily:"Inter",fontWeight:600,fontSize:"11px",color:"#192228",padding:"0em 0em 0em 2em"}}>Store Details</p>
           <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding:"0em 1em" }}>
            <span style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", paddingLeft: "1em" }}>Location Address</span>
            <Switch style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", padding: "0em 1em", textAlign: "end" }} />
          </div>
          
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding:"0em 1em",marginTop:"1em"  }}>
            <span style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", paddingLeft: "1em" }}>Location phone number</span>
            <Switch style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", padding: "0em 1em", textAlign: "end" }} />
          </div>
          <hr style={{opacity:0.1,margin:"1em 0em"}}/>
          <p style={{ fontFamily:"Inter",fontWeight:600,fontSize:"11px",color:"#192228",padding:"0em 0em 0em 2em"}}>Order Details</p>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding:"0em 1em"  }}>
            <span style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", paddingLeft: "1em" }}>Total Sales Box</span>
            <Switch style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", padding: "0em 1em", textAlign: "end" }} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding:"0em 1em",marginTop:"1em"  }}>
            <span style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", paddingLeft: "1em" }}>SKU</span>
            <Switch style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", padding: "0em 1em", textAlign: "end" }} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",padding:"0em 1em",marginTop:"1em"  }}>
            <span style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", paddingLeft: "1em" }}>Staff attributed to sale</span>
            <Switch style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "13px", padding: "0em 1em", textAlign: "end" }} />
          </div>
          <p style={{ fontFamily:"Inter",fontWeight:600,fontSize:"11px",color:"rgba(10, 2, 19, 0.67)",padding:"0em 0em 0em 2em"}}>Barcode</p>
          <Select className='sizeSelect' suffixIcon={suffixIcon} placeholder={<span style={{fontFamily:"Inter",fontSize:"14px",fontWeight:400,paddingLeft:"",color:"black"}}>2D Barcode </span>} style={{ width: "90%" ,paddingLeft:"1em",borderRadius:'5px',borderColor:'#d9d9d9',border:'0.5px'}}>
                    
                    </Select>
          </Col>
          <Col span={18}>
        {/* Conditionally render the template preview based on selected printer type */}
        {selectedPrinterType === 'A4' && (
            <Row style={{margin:"2em"}}>
              <Col span={2}/>
            <Col style={{ padding: '1em', backgroundColor: 'white' }} span={20} >
            <div style={{display:"flex",flexDirection:"column",justifyItems:"center",alignItems:"center"}}>
              <h4 style={{fontSize:"1.875rem",lineHeight:"2.25rem",fontWeight:600,marginTop:"1.25rem"}} className=" font-semibold mt-5">Preview Invoice Pdf</h4>
              <div
              style={{
                minHeight: "85vh",
                padding: "1rem",
                paddingRight: "2rem",
                paddingLeft: "2rem",
                maxWidth: "45rem",
                margin: "0 auto",
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)'
              }}
                ref={componentRef}
              >
                <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div>
                    {/* {selectedImageUrl && (
                      <img className="w-36" alt="logo" src={selectedImageUrl} />
                    )} */}
                    <div style={{marginTop:"1.25rem",padding:"0 1rem"}}>
                      <p style={{fontWeight:700,fontFamily:"Inter"}}>CW SUITE</p>
                      <p style={{ whiteSpace: 'pre-line',fontFamily:"Inter" }}>TOPAZ Building</p>
                      <p>67879</p>
                    </div>
                    <div style={{marginTop:"1.25rem",padding:"0 1rem"}}>
                      <p >Bill To:</p>
                      <p style={{fontWeight:700,fontFamily:"Inter"}}>Abraar</p>
                      <p>1327</p>
                      <p  style={{ whiteSpace: 'pre-line',fontFamily:"Inter" }}>
                        Punjagutta
                      </p>
                    </div>
                  </div>
                  <div style={{marginTop:"0.75rem"}}>
                    <p style={{fontSize:"2.5rem",textTransform:"uppercase",fontFamily:"Inter",textAlign:"end"}}>Invoice</p>
                    <p style={{fontSize:"18px",textTransform:"uppercase",textAlign:"end",fontFamily:"Inter"}}>
                      # 3445
                    </p>
                    <div style={{marginTop: "2.5rem",alignItems:"flex-end",display:"flex",flexDirection:"column"}}>
                      <div style={{display:"flex",justifyContent:"space-between",columnGap:"1.25rem",width:"20rem"}}>
                        <p style={{width:"8rem",justifyContent:"flex-end",display:"flex",fontFamily:"Inter"}}>Date:</p>
                        <p>23/08/2023</p>
                      </div>
                      <div style={{display:"flex",justifyContent:"space-between",columnGap:"1.25rem"}}>
                        <p style={{width:"8rem",justifyContent:"flex-end",display:"flex",fontFamily:"Inter"}}>
                          Payment Terms:
                        </p>
                        <p>No Terms</p>
                      </div>
                      <div style={{justifyContent:"space-between",display:"flex",width:"20rem"}}>
                        <p style={{width:"8rem",justifyContent:"flex-end",display:"flex",fontFamily:"Inter"}}>
                          Due Date:
                        </p>
                        <p>01/09/2023</p>
                      </div>
                      <div style={{justifyContent:"space-between",display:"flex",width:"20rem"}}>
                      <p style={{width:"8rem",justifyContent:"flex-end",display:"flex",fontFamily:"Inter"}}>
                          PO Number:
                        </p>
                        <p>123</p>
                      </div>
                      <div style={{display:"flex",justifyContent:"flex-end",width:"24rem",borderRadius:"0.375",paddingRight:"0.5rem",padding:"0.5rem 0"}}>
                        <div style={{display:"flex",justifyContent:"space-between",width:"20rem"}}>
                          <p style={{justifyContent:"flex-end",fontFamily:"Inter",display:"flex",fontSize:"18px",fontWeight:700,width:"8rem"}}>
                            Balance Due:
                          </p>
                          <p style={{fontSize:"18px",fontWeight:700,textAlign:"end",fontFamily:"Inter"}}>
                            30,000.90 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-20">
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', backgroundColor: 'rgba(0, 0, 0, 0.75)', padding: '5px', borderRadius: '4px' }}>
                  <div style={{ gridColumn:'span 5', color: 'white',fontFamily:"Inter" }}>Item</div>
                  <div style={{ gridColumn: 'span 3', color: 'white',fontFamily:"Inter" }}>Quantity</div>
                  <div style={{ gridColumn: 'span 2', color: 'white',fontFamily:"Inter" }}>Rate</div>
                  <div style={{ gridColumn: 'span 2', color: 'white', textAlign: 'end',fontFamily:"Inter" }}>Amount</div>
                </div>

                  {/* {productLine.map((item, i) => ( */}
                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', padding: '5px' ,fontFamily:"Inter"}}>
                    <p style={{ gridColumn: 'span 5', whiteSpace: 'pre-line', }}>Charger</p>
                    <p style={{ gridColumn: 'span 3' }}>2</p>
                    <p style={{ gridColumn: 'span 2' }}>100</p>
                    <p style={{ gridColumn: 'span 2', textAlign: 'end' }}>200</p>
                  </div>
                  {/* ))} */}
                </div>
                <div style={{marginTop:"2.5rem",padding:"0 1.25rem",width:"100%",display:"flex",flexDirection:"column",alignItems:"flex-end",rowGap:"1rem"}}>
                <div style={{ display: 'flex', columnGap:"1.25rem", width: '80%', justifyContent: 'space-between' }}>
                  <p style={{ color: 'gray', width: '8rem', display: 'flex', justifyContent: 'flex-end',fontFamily:"Inter" }}>Subtotal:</p>
                  <p>200</p>
                </div>
                  <div style={{ display: 'flex', columnGap:"1.25rem", width: '20rem', justifyContent: 'space-between' }}>
                    <p style={{ color: 'gray', width: "8rem", display: 'flex', justifyContent: 'flex-end',fontFamily:"Inter" }}>Tax:</p>
                    <p>2 %</p>
                  </div>
                  <div style={{ display: 'flex', columnGap:"1.25rem", width: '20rem', justifyContent: 'space-between' }}>
                     <p style={{ color: 'gray', width: "8rem", display: 'flex', justifyContent: 'flex-end',fontFamily:"Inter" }}>Discount:</p>
                    <p>2 %</p>
                  </div>
                  <div style={{ display: 'flex', columnGap:"1.25rem", width: '20rem', justifyContent: 'space-between' }}>
                     <p style={{ color: 'gray', width: "8rem", display: 'flex', justifyContent: 'flex-end',fontFamily:"Inter" }}>Shipping:</p>
                    <p>2 %</p>
                  </div>
                  <div style={{ display: 'flex', columnGap:"1.25rem", width: '20rem', justifyContent: 'space-between' }}>
                     <p style={{ color: 'gray', width: "8rem", display: 'flex', justifyContent: 'flex-end',fontFamily:"Inter" }}>Total:</p>
                    <p>
                     230
                    </p>
                  </div>
                </div>
                <div style={{ marginTop: '5px' }}>
                  <p style={{ fontSize: '14px', marginBottom: '1px', color: 'gray',fontFamily:"Inter" }}>Notes</p>
                  <p style={{ whiteSpace: 'pre-line' ,fontFamily:"Inter"}}>Please Check</p>
                </div>
                <div style={{ marginTop: '5px' }}>
                  <p style={{ fontSize: '14px', marginBottom: '1px', color: 'gray',fontFamily:"Inter" }}>Terms</p>
                  <p style={{ whiteSpace: 'pre-line',fontFamily:"Inter" }}>Please Check</p>
                </div>

              </div>
            </div>
            </Col>
            <Col span={2}/>
            </Row>
        )}
        {selectedPrinterType === 'Thermal' && (
          <Row style={{margin:"2em"}}>
             <Col span={9}/>
            <Col style={{ padding: '1em', backgroundColor: 'white' }} ref={componentRef} span={6}>
              <p style={{ fontFamily: 'Inter' }}>exceloid-test</p>
              <div style={{ border: '1px solid #0A0213', borderRadius: '4px', paddingLeft: '2.5em' }}>
                <span>Total</span><br />
                <span>$0.699</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #000' }}>
                <p>Item</p>
                <p>Price</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px dashed #000', paddingBottom: '0.5rem' }}>
                <p>Item</p>
                <p>$12</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px dashed #000', paddingBottom: '0.5rem' }}>
                <p>Multiple Quantity Item</p>
                <p>$12</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px dashed #000', paddingBottom: '0.5rem' }}>
                <p>Discounted Item</p>
                <p>$12</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px dashed #000', paddingBottom: '0.5rem' }}>
                <p>Gift Card</p>
                <p>$12</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.2em', paddingBottom: '0.2em', fontWeight: 600 }}>
                <p>SUB TOTAL</p>
                <p>$61</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.2em', paddingBottom: '0.2em', fontWeight: 600 }}>
                <p>DISCOUNT</p>
                <p>$61</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.2em', paddingBottom: '0.2em', fontWeight: 600 }}>
                <p>SHIPPING</p>
                <p>$61</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.2em', paddingBottom: '0.2em', fontWeight: 600 }}>
                <p>TAX</p>
                <p>$61</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.4em', paddingBottom: '0.2em', fontWeight: 600, borderBottom: '1px dashed #000' }}>
                <p>TOTAL</p>
                <p>$61</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.2em', paddingBottom: '0.2em' }}>
                <p>Cash</p>
                <p style={{ fontWeight: 600 }}>$61</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.2em', paddingBottom: '0.2em' }}>
                <p>Change Due</p>
                <p style={{ fontWeight: 600 }}>$61</p>
              </div>
            </Col>
            <Col span={9}/>
            </Row>
        )}
      </Col>
        </Row>
       
    </div>
  )
}
export default DigitalReceipt;
