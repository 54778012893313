import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Col, Spin, Row } from "antd";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../lib/storage";
import { getToken } from "../../services/token.js";
import { getUser, get360MenuList, getUserPreferencesData, updateLocalToken,getGlobalParameters } from "../../services/generic";
import { updateCustomLocalToken } from "../../services/custom";
import loginLogo from "../../assets/images/NewLogoCW.svg";
import "antd/dist/antd.css";
import "../../styles/app.css";
import "./login.css";
import ThemeJson from "../../constants/UIServer.json";
import { Link } from 'react-router-dom';
// import ResetPassword from "./NewPassword";

const Login = (props) => {
  const [loading, setLoading] = useState({ status: false, message: "" });
  const { globalStore, setGlobalStore } = useGlobalContext();
  const history = useHistory();

  useEffect(() => {
    const { authTokens } = globalStore;
    if (authTokens) {
      history.push("/");
    }
  }, []);

  const onLogin = async (values) => {
    const username = values.username;
    const password = values.password;

     if (username === '' && password === '' || username === undefined && password === undefined || username === null && password === null) {
      message.error("Please Enter Username and Password")
    }else if (username === '' || username === undefined || username === null) {
      message.error("Please Enter Username")
    }
    else if (password === '' || password === undefined || password === null) {
      message.error("Please Enter Password")
    }
    else {
      setLoading({ status: true, message: ""});
      localStorage.clear();
      setGlobalStore({ authTokens: null, userData: null, sideMenuData: null, userPreferences: null,globalPreferences:null, windowTabs: [] });
      try {
        const resTokenData = await getToken(username, password);
        localStorage.setItem("authTokens", JSON.stringify(resTokenData));
        updateLocalToken();
        updateCustomLocalToken();

        const userDataResponse = await getUser(username);
        // userDataResponse.username = username
        if (!userDataResponse) {
          throw new Error("Invalid User Data Response");
        }

        if (userDataResponse.CW360_V2_UI === null || userDataResponse.CW360_V2_UI === undefined) {
          userDataResponse.CW360_V2_UI = ThemeJson;
        } else {
          userDataResponse.CW360_V2_UI = JSON.parse(userDataResponse.CW360_V2_UI);
        }

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          userDataResponse.CW360_V2_UI = ThemeJson;
        }
        localStorage.setItem("userData", JSON.stringify(userDataResponse));

        const userPreferencesResponse = await getUserPreferencesData();
        localStorage.setItem("userPreferences", JSON.stringify(userPreferencesResponse));
        
        const globalParametersResponse = await getGlobalParameters();
        localStorage.setItem("globalParameters", JSON.stringify(globalParametersResponse));

        const menuDataResponse = await get360MenuList(userDataResponse.role_id);
        localStorage.setItem("sideMenuData", JSON.stringify(menuDataResponse));
        localStorage.setItem("subMenuData", JSON.stringify([]));



        setGlobalStore({ authTokens: resTokenData, userData: userDataResponse, sideMenuData: menuDataResponse, userPreferences: userPreferencesResponse,globalPreferences:globalParametersResponse });
        setLoading({ status: false, message: "" });

        const refState = props.location.state;

        if (refState !== undefined && refState.referer.pathname !== "/login") {
          history.push(props.location.state.referer.pathname);
        } else {
          history.push("/");
        }
      } catch (error) {
        console.error("Login Failed:", error);
        if (JSON.parse(JSON.stringify(error)).message === "Request failed with status code 400") {
          message.error("Bad credentials, try again");
        } else {
          message.error("Some thing went wrong, Try again later");
        }
        localStorage.clear();
        setGlobalStore({ authTokens: null, userData: null, sideMenuData: null, userPreferences: null, windowTabs: [] });
        setLoading({ status: false, message: "" });
      }
    }
    window.location.reload();
  };

  const onLoginInvalid = (error) => {
    console.error("Login Failed:", error);
  };
  const loginLogoFromEnv = process.env.REACT_APP_LOGO_URL
  return (
<div className="mainDiv col-ms-12  ">
      
      <div className="innerBlock col-ms-10">
        <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "30px", color: "#0C173A", opacity: 0.9 }} />} spinning={loading.status} tip={loading.message}>
          <Row>
            <Col span={2}></Col>
            <Col span={16} offset={4} >
              <img className="cwLoginLogo" src={loginLogoFromEnv?loginLogoFromEnv:loginLogo}></img></Col>
            <Col span={2}></Col>
          </Row>
          <Form onFinish={onLogin} onFinishFailed={onLoginInvalid}>
            <Form.Item name="username">
              <Col span={22}>
                <Input className = "usernameInput"style={{ backgroundColor: "#D8DADE", fontSize: "1rem", borderRadius: 5, borderColor: "#D8DDE6", marginTop: "5%", marginLeft: "1.2em", height: "3.7em" }} placeholder="Username" />
              </Col>
            </Form.Item>
            <Form.Item name="password">
              <Col span={22}>
                <Input.Password visibilityToggle = {false} className="passwordColorBackground" style={{ backgroundColor: "#D8DADE", fontSize: "1rem", borderRadius: 5, borderColor: "#D8DDE6", marginTop: "7.5%", marginLeft: "1.2em", height: "3.7em" }} placeholder="Password" />
              </Col>
            </Form.Item>
            <Form.Item><Col span={22}>
              <Button style={{ backgroundColor: "#A0C838", fontWeight: 600, borderRadius: 5, borderColor: "#D8DDE6", marginTop: "7.5%", letterSpacing: "0.32px", marginLeft: "1.2em", width: "100%", height: "3.7em" }}
                type="primary" htmlType="submit">Login</Button></Col></Form.Item>

            <div className="resetPasswordLog">
              <Row> 
                <Col><h4 style={{ marginLeft: "1.2em" }}>Forgot your password ?</h4></Col>
                <Col><Link style={{ marginLeft: "0.80em" }} to="./NewPassword">Reset Password </Link></Col>
              </Row>
            </div>
          </Form>
        </Spin>
      </div>

      <div className="copyright col-ms-10">
        <Col>
          <h5 style={{ fontSize: "0.85em", textAlign: "center", color: "#FAFAFAE0", fontWeight: 400 }}>This site uses cookies to manage user authentication, analytics, and to provide an improved digital experience. You can learn more about the cookies we use as well as how you can change your cookie settings by clicking here. By continuing to use this site without changing your settings, you are agreeing to our use of cookies. Review CW Suite's Privacy Statement to learn more.</h5>
        </Col>
        </div>

      <div className="col-ms-10">
        <Col>
          <h5 style={{ fontSize: "0.85em", marginTop: "8%", textAlign: "center", color: "#FAFAFAE0", fontWeight: 400 }}>Copyright 2020 CW Suite | All Rights Reserved</h5>
        </Col>
        </div>

    </div>
  );
};

export default Login;
