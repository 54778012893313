import React, { useState,useEffect } from "react";
import { Card, Spin } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { LoadingOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../lib/storage";
import RecordHeader from "./RecordHeader";
import RecordLines from "./RecordLines";
import RecordTitle from "./RecordTitle";
import RecordFooter from "./RecordFooter";

const RecordWindow = (props) => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const [lastRefreshed, setLastRefreshed] = useState(new Date());
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formFlag,setFormFlag] = useState(false)
  const [headerRecordData, setHeaderRecordData] = useState({});
  const [loadingRecordWindow, setLoadingRecordWindow] = useState(false);
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const innerWidth = windowSize.innerWidth
  return (
    <Card style={Themes.contentWindow.recordWindow.mainCard} bodyStyle={{backgroundColor:"#ffffff"}}>
      <Spin indicator={<LoadingOutlined className="loader" style={Themes.contentWindow.mainCard} spin />} spinning={loadingRecordWindow}>
        {/* <RecordTitle lastRefreshed={lastRefreshed} setLastRefreshed={setLastRefreshed} headerRecordData={headerRecordData} isHeaderActive={isHeaderActive} {...props} /> */}
        {innerWidth>768?
        <>
        <RecordHeader
          setIsHeaderActive={setIsHeaderActive}
          isEditMode={isEditMode}
          formFlag={formFlag}
          setIsEditMode={setIsEditMode}
          headerRecordData={headerRecordData}
          isHeaderActive={isHeaderActive}
          lastRefreshed={lastRefreshed}
          setLastRefreshed={setLastRefreshed}
          setHeaderRecordData={setHeaderRecordData}
          loadingRecordWindow={loadingRecordWindow}
          setLoadingRecordWindow={setLoadingRecordWindow}
          {...props}
        />
        <RecordLines
          isHeaderActive={isHeaderActive}
          lastRefreshed={lastRefreshed}
          setLastRefreshed={setLastRefreshed}
          headerRecordData={headerRecordData}
          loadingRecordWindow={loadingRecordWindow}
          setLoadingRecordWindow={setLoadingRecordWindow}
          {...props}
        /> 
        </>:
        <Scrollbars
        style={{
          height: "81vh"
        }}
        // ref={scrollbarRef}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbSize={90}
        renderView={renderView}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
      >
        <RecordHeader
        setIsHeaderActive={setIsHeaderActive}
        isEditMode={isEditMode}
        formFlag={formFlag}
        setIsEditMode={setIsEditMode}
        headerRecordData={headerRecordData}
        isHeaderActive={isHeaderActive}
        lastRefreshed={lastRefreshed}
        setLastRefreshed={setLastRefreshed}
        setHeaderRecordData={setHeaderRecordData}
        loadingRecordWindow={loadingRecordWindow}
        setLoadingRecordWindow={setLoadingRecordWindow}
        {...props}
      />
      <RecordLines
        isHeaderActive={isHeaderActive}
        lastRefreshed={lastRefreshed}
        setLastRefreshed={setLastRefreshed}
        headerRecordData={headerRecordData}
        loadingRecordWindow={loadingRecordWindow}
        setLoadingRecordWindow={setLoadingRecordWindow}
        {...props}
      />
      </Scrollbars>}
         {innerWidth>768 ? "":
        <div style={{ position: 'fixed',height:"3.5em",paddingTop:"0.7em", bottom: 0, width: '100%',backgroundColor:"white",borderTop:"1px solid #dadada"}}>
        <RecordFooter
        setIsHeaderActive={setIsHeaderActive}
        isEditMode={isEditMode}
        setFormFlag={setFormFlag}
        setIsEditMode={setIsEditMode}
        headerRecordData={headerRecordData}
        isHeaderActive={isHeaderActive}
        lastRefreshed={lastRefreshed}
        setLastRefreshed={setLastRefreshed}
        setHeaderRecordData={setHeaderRecordData}
        loadingRecordWindow={loadingRecordWindow}
        setLoadingRecordWindow={setLoadingRecordWindow}
        {...props}/>
        </div>}
      </Spin>
    </Card>
  );
};

export default RecordWindow;
