import React, { useEffect, useState, useRef } from "react";
import { Layout, Spin, Card, Tabs,Row,Col,Menu,message } from "antd";
import { LoadingOutlined,MenuFoldOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useGlobalContext } from "../../lib/storage";
import { Scrollbars } from "react-custom-scrollbars";
import NavBar from "../navBar";
import SideMenu from "../sideMenu";
import ErrorBoundary from "../errorBoundary";
import "antd/dist/antd.css";
import "./index.css"
import SingleNav from "../navBar/singleNav";
import SideNav from "../sideMenu/sideNav";

const { Header, Content } = Layout;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const AdminLayout = (props) => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { userPreferences, windowTabs, userData } = globalStore;
  const Themes = userData.CW360_V2_UI;
  const [loading, setLoading] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [menuToggle, setMenuToggle] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [showExpandedMenu, setShowExpandedMenu] = useState(false); 
  const [fullMenuToggle, setFullMenuToggle] = useState(true);
  const [mainSider,setMainSider]= useState(true)
  const [showToggler,setShowToggler] = useState(false)
  const [drawerFlag, setDrawerFlag] = useState(false);
  const history = useHistory();
  const homePath = history.location.pathname
  const myArray = history.location.pathname.split("/")
  const lastElement = myArray[myArray.length - 1];
  const subMenuData = JSON.parse(localStorage.getItem("subMenuData"))
  const [openKeys, setOpenKeys] = useState(() => {
    // Set the initial state of openKeys using a function
    const subMenuData = JSON.parse(localStorage.getItem("subMenuData"));
    return subMenuData.map((menu) => menu.key);
  });

  useEffect(() => {
    if(lastElement === "alerts"){
     setCollapsed(true)
    }
    if(lastElement === "myApps"){
      setMainSider(false)
      setCollapsed(true)
    }else{
    //  setCollapsed(false)
     setMainSider(true)
    }
   }, [lastElement]);
   
  useEffect(() => {
    const newOpenKeys = subMenuData.map((menu) => menu.key);
    // Only update openKeys if it has changed to avoid an infinite loop
    if (JSON.stringify(openKeys) !== JSON.stringify(newOpenKeys)) {
      setOpenKeys(newOpenKeys);
    }
  }, [subMenuData]);

  let lowerSubMenuItem =JSON.parse(localStorage.getItem("lowerSubMenuItem"))
  let subMenuItem = JSON.parse(localStorage.getItem("subMenuItem"))
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
      height:"100px"
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle,marginBottom:windowType==="Dashboard"&&sideNav !== "Y"?"7em":""  }} {...props} />;
  };

  const renderView1 = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle,position:windowType==="Home"?"":"" }} {...props} />;
  };

  const selectMenuToNavigate = (data) => {
    
    localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.key));
    if (data.type === "Report") {
      localStorage.setItem("windowType","Report")
      history.push(`/reports/report/${data.id}`);
    } else if (data.type === "Dashboard") {
      localStorage.setItem("windowType","Dashboard")
      history.push(`/analytics/dashboard/${data.id}`);
    } else if (data.type === "Generic") {
      localStorage.setItem("windowType","Generic")
      history.push(`/window/list/${data.id}`);
    } else if (data.type === "Custom") {
      history.push(`/others/window/${data.id}`);
    } else {
      message.warning("Not Available");
    }
  };

  const subMenuNavigate = (data) => {
    if (data.children === undefined || data.children === null) {
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      localStorage.setItem("lowerSubMenu",null)
      localStorage.setItem("windowType",data.type)
      localStorage.setItem("lowerSubMenuItem", null)

      // setMenuToggle(true)
      // setFullMenuToggle(true)
      if (data.type === "Report") {
        history.push(`/reports/report/${data.id}`);
      } else if (data.type === "Dashboard") {
        history.push(`/analytics/dashboard/${data.id}`);
      } else if (data.type === "Generic") {
        history.push(`/window/list/${data.id}`);
      } else if (data.type === "Custom") {
        history.push(`/others/window/${data.id}`);
      }
    }
    else {
      // setMenuToggle(false)
      localStorage.setItem("lowerSubMenu", JSON.stringify(data.children))
      localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.children[0].key))
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      localStorage.setItem("windowType",data.type)
      if (data.children[0].type === "Report") {
        localStorage.setItem("windowType","Report")
        history.push(`/reports/report/${data.children[0].id}`);
      } else if (data.children[0].type === "Dashboard") {
        localStorage.setItem("windowType","Dashboard")
        history.push(`/analytics/dashboard/${data.children[0].id}`);
      } else if (data.children[0].type === "Generic") {
        history.push(`/window/list/${data.children[0].id}`);
      } else if (data.children[0].type === "Custom") {
        history.push(`/others/window/${data.children[0].id}`);
      }
    }
  };

  let sideNav = Themes.appTheme.sideNavigation

  useEffect(() => {
    if(homePath === "/"){
      localStorage.setItem("windowType","Home")
    }
    setTimeout(() => setLoading(false), 200);
  }, [homePath]);

  const [activeKey, setActiveKey] = useState(0);
  const onChangeKey = (key) => {
    history.push(windowTabs[key].url);
    setActiveKey(key);
  };

  const initialExc = useRef(false);
  useEffect(() => {
    if (initialExc.current) {
      if (userPreferences.enableMultiTab === "Y" && windowTabs.length > 0) {
        if (windowTabs[parseInt(activeKey)].content === null) {
          const localwindowTabs = [...windowTabs];
          localwindowTabs[parseInt(activeKey)].content = props.children;
          setGlobalStore({ windowTabs: [...localwindowTabs] });
        }
      }
    }
  }, [activeKey]);

  const editTab = (tabKey, action) => {
    if (action === "remove") {
      removeTab(tabKey);
    }
  };

  const removeTab = (key) => {
    const localwindowTabs = [...windowTabs];
    const tabKey = parseInt(key);
    localwindowTabs.splice(tabKey, 1);
    setGlobalStore({ windowTabs: [...localwindowTabs] });
    let newKey = 0;
    if (tabKey >= 1) {
      newKey = tabKey - 1;
    }
    setActiveKey(`${newKey}`);
    history.push(windowTabs[newKey].url);
  };

  useEffect(() => {
    if (userPreferences.enableMultiTab === "Y") {
      if (windowTabs.length > 0) {
        const localwindowTabs = [...windowTabs];
        const tabIdx = localwindowTabs.findIndex((tab) => tab.url === history.location.pathname && tab.content === null);
        if (tabIdx >= 0) {
          localwindowTabs[tabIdx].content = props.children;
          setGlobalStore({ windowTabs: [...localwindowTabs] });
          setActiveKey(`${tabIdx}`);
          const localStorageTabs = [];
          localwindowTabs.map((tab) => {
            const newTabData = { ...tab, content: null };
            return localStorageTabs.push(newTabData);
          });
          localStorage.setItem("windowTabs", JSON.stringify(localStorageTabs));
        }
      } else {
        const newWindowTab = [];
        newWindowTab.push({
          url: history.location.pathname,
          title: "Window",
          content: props.children,
        });
        setGlobalStore({ windowTabs: [...newWindowTab] });
        setActiveKey(`0`);
        newWindowTab[0].content = null;
        localStorage.setItem("windowTabs", JSON.stringify(newWindowTab));
      }
      initialExc.current = true;
    }
  }, [windowTabs]);

  const onClose = () => {
    setDrawerFlag(false);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
const windowType = localStorage.getItem('windowType')
const innerWidth = windowSize.innerWidth

  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={Themes} spin />} spinning={loading}>
    {sideNav === "Y"?
     <Layout style={{ fontFamily: "'Open Sans', sans-serif", minHeight: '100vh' }}>
     {/* <Layout style={{ display: 'flex', flex: '1', overflow: 'hidden' }}> */}
       {innerWidth > 768 &&  mainSider && (
         // <div style={{ position: '', top: 50, bottom: 0, left: 0, zIndex: 99, overflowY: 'hidden' }}>
           <SideNav  collapsed= {collapsed} setCollapsed={setCollapsed} showToggler={showToggler} setShowToggler={setShowToggler} showExpandedMenu={showExpandedMenu} setShowExpandedMenu={setShowExpandedMenu} setMenuToggle={setMenuToggle} menuToggle={menuToggle} drawerFlag={drawerFlag} onClose={onClose} />
         // </div>
       )}
       <Layout style={{ flex: '1' }}>
       <Header style={{ backgroundColor: '#FFFFFF', padding: 0, height: 'auto', position: 'sticky', zIndex: 100, top: 0 }}>
       <SingleNav collapsed={collapsed} setCollapsed={setCollapsed} showToggler={showToggler} setShowToggler={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle} menuToggle={menuToggle} setDrawerFlag={setDrawerFlag} drawerFlag={drawerFlag} setMenuToggle={setMenuToggle} />
       </Header>
       <Content style={{ background: "#f3f4f9" }}>
         <Row style={{width:"100%",height:collapsed?"":"100%"}}>
         <div style={{ width: collapsed && subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0? '0' : '17em', overflow: 'hidden', transition: 'width 0.3s',display:innerWidth>768 && subMenuData.length >0 ?"block":"none",paddingTop:"0.7em",backgroundColor:"#fff" }}>
        
          <MenuFoldOutlined style={{display:collapsed?"none":"block",paddingRight:"1em", transition: 'all 0.3s',float:"right"}} onClick={e => { e.stopPropagation(); setCollapsed(!collapsed)}}  />
          
        {subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0 ? (
         <Scrollbars
           style={{
             width: "100%",
             height:"100%",
             transition: 'all 0.3s',
           }}
           universal
           hidden={false}
          //  hideTracksWhenNotNeeded={true}
           thumbSize={90}
           renderView={renderView}
           renderThumbHorizontal={renderThumb}
           renderThumbVertical={renderThumb}
         >
           <Menu selectedKeys={lowerSubMenuItem} className="lineMenu" openKeys={openKeys} style={{backgroundColor:"white",color:"#0C173A",padding:"0 1em"}} mode="inline" >
               {subMenuData.map((menu, index) =>
               menu.children && menu.children.length > 0 ? (
                 <SubMenu
                   key={menu.key}
                   style={{background:"#fff"}}
                   title={
                     <span title={menu.title} style={{fontSize:"12px",display:"flex",justifyContent: "space-between", alignItems: "center"}}>
                       {menu.title}
                       {/* Add the icon conditionally for the first menu */}
                     </span>
                   }
                   className="lineMenu"
                   // onTitleClick={()=>{console.log("2item")}}
                 >
                   {menu.children.map((subMenuItem, ind) =>
                     <Menu.Item
                       key={subMenuItem.key}
                       style={{ width: 'auto', paddingLeft: '10px' }}
                       onClick={() => selectMenuToNavigate({ type: subMenuItem.type, id: subMenuItem.id, key: subMenuItem.key, title: subMenuItem.title, index: index })}
                     // style={{width:'auto',overflow:'visible',textOverflow:'clip',whiteSpace:'normal',lineHeight:'15px'}}
                     >
                       <span style={{}} title={subMenuItem.title}  >
                         {subMenuItem.title}
                       </span>
                     </Menu.Item>
 
                   )}
                 </SubMenu>
               ) : (
                 <Menu.Item
                   key={menu.key}
                   selectedKeys={subMenuItem}
                   style={{ width: 'auto', paddingLeft: "0px" }}
                   onClick={() => subMenuNavigate({ type: menu.type, id: menu.id, key: menu.key, children: menu.children })}
                 // style={{width:'auto',overflow:'visible',textOverflow:'clip',whiteSpace:'normal',lineHeight:'15px'}}
                 >
                  <span title={menu.title} style={{ fontWeight: 600, display: "flex", justifyContent: "space-between", alignItems: "center",fontSize:"12px"}}>
                     {menu.title}
                   </span>
                 </Menu.Item>
               )
             )}
               </Menu>
         </Scrollbars>
       ) : null}
       </div>
       <div style={{ width: !collapsed &&innerWidth>768 && subMenuData !== undefined && subMenuData !== null && subMenuData.length !== 0 ? 'calc(100% - 17em)' : '100%', transition: 'width 0.3s',overflow:"hidden" }}>
       {/* {windowType !== 'Dashboard' && windowType !== 'Report' ? ( */}
       <Scrollbars 
        style={{
          width: "100%",
          height:"100%",
          position:!collapsed?"relative":"absolute",
          transition: 'all 0.3s'
        }}
        universal
        hidden={false}
        hideTracksWhenNotNeeded={true}
        autoHideTimeout={1000}
        autoHideDuration={1000}
        thumbSize={90}
        renderView={renderView}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}>
             <div className="mainLayoutPadding">
               {userPreferences.enableMultiTab === 'Y' ? (
                 <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
                   {windowTabs.map((tab, index) => (
                     <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                       <ErrorBoundary>{tab.content}</ErrorBoundary>
                     </TabPane>
                   ))}
                 </Tabs>
               ) : (
                 <ErrorBoundary>{props.children}</ErrorBoundary>
               )}
             </div>
             </Scrollbars>
           {/* ) : null} */}
           </div>
           </Row>
     </Content>
       </Layout>
   </Layout>:
    <Layout style={{ display: loading ? "none" : "block", fontFamily: "'Open Sans', sans-serif"}}>
        <Header  style={{backgroundColor:'#FFFFFF',padding:0,height:innerWidth>768?'120px':'45px',position:'sticky',zIndex:100,left:0,right:0,top:0}}>
          <NavBar showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle}  menuToggle={menuToggle} setDrawerFlag={setDrawerFlag} drawerFlag={drawerFlag} setMenuToggle={setMenuToggle}/>
        </Header>
        <Layout style={{backgroundColor:windowType==="Dashboard" ||windowType==="Report" || windowType === "kanban" ? Themes.appTheme.analyticsBackgroundColor: Themes.appTheme.primaryBackgroundColor}}>
        {innerWidth>768?
          <SideMenu showToggler={showToggler} setShowToggler ={setShowToggler} fullMenuToggle={fullMenuToggle} setFullMenuToggle={setFullMenuToggle} setMenuToggle={setMenuToggle} menuToggle={menuToggle} drawerFlag={drawerFlag} onClose={onClose} />
        :null}
        {windowType !== "Dashboard" && windowType !=="Report" && windowType !== "kanban" && windowType !== "Home" ? 
           <Content className={`${menuToggle && innerWidth>768?"mainLayoutPadding1":"mainLayoutPadding"}`}>
           <Scrollbars
           style={{
             height:"100%",
             width:menuToggle?"97%":"100%",
             position:menuToggle || innerWidth<768 ?"fixed":"relative"
           }}
            hideTracksWhenNotNeeded
            autoHide={false}
            renderThumbHorizontal={renderThumb}
            renderThumbVertical={renderThumb}
            renderView={renderView}>
          {/* <Card  bodyStyle={{padding:"0px",marginTop:"1em"}}> */}
          <div style={{borderRadius:'6px 6px 0px 0px'}}>
  
            {userPreferences.enableMultiTab === "Y" ? (
              <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
                {windowTabs.map((tab, index) => (
                  <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                    <ErrorBoundary>{tab.content}</ErrorBoundary>
                  </TabPane>
                ))}
              </Tabs>
            ) : (
              <ErrorBoundary>{props.children}</ErrorBoundary>
            )}
            </div>
          {/* </Card> */}
          </Scrollbars>
      </Content>:
      windowType === "Dashboard"?
          <Content className={menuToggle?"mainLayoutDashboardPadding1":"mainLayoutDashboardPadding"}>
          <div style={{padding:menuToggle?"1em":"",overflow:"hidden"}}>
          <Scrollbars
            style={{
              height:"100%",
              width:menuToggle?"96%":"82%",
              position:menuToggle?"fixed":"absolute",
            }}
            autoHide
            renderThumbHorizontal={renderThumb}
            renderThumbVertical={renderThumb}
            renderView={renderView}>
            {userPreferences.enableMultiTab === "Y" ? (
              <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
                {windowTabs.map((tab, index) => (
                  <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                    <ErrorBoundary>{tab.content}</ErrorBoundary>
                  </TabPane>
                ))}
              </Tabs>
            ) : (
              <ErrorBoundary>{props.children}</ErrorBoundary>
            )}
            </Scrollbars> 
           </div>
                  
      </Content>:
      windowType === "Report" ?
      <Content className="mainLayoutReportPadding">
      <div >
        {userPreferences.enableMultiTab === "Y" ? (
          <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
            {windowTabs.map((tab, index) => (
              <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
                <ErrorBoundary>{tab.content}</ErrorBoundary>
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <ErrorBoundary>{props.children}</ErrorBoundary>
        )}
       </div>        
  </Content>:
   windowType === "Home"?
      <Content className={`${menuToggle?"mainLayoutPadding1":"mainLayoutPadding"}`}>
      <Scrollbars
      style={{
        height:"100%",
        width:"100%"
      }}
      hideTracksWhenNotNeeded
      autoHide
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      renderView={renderView1}>
    {/* <Card  bodyStyle={{padding:"0px",marginTop:"1em"}}> */}
    <div style={{borderRadius:'6px 6px 0px 0px'}}>
      {userPreferences.enableMultiTab === "Y" ? (
        <Tabs hideAdd onChange={onChangeKey} activeKey={activeKey} type="editable-card" onEdit={editTab}>
          {windowTabs.map((tab, index) => (
            <TabPane forceRender={false} tab={tab.title} key={index} closable={windowTabs.length > 1 ? true : false}>
              <ErrorBoundary>{tab.content}</ErrorBoundary>
            </TabPane>
          ))}
        </Tabs>
      ) : (
        <ErrorBoundary>{props.children}</ErrorBoundary>
      )}
      </div>
    {/* </Card> */}
    </Scrollbars>
    </Content>:"" }
        </Layout>
      </Layout>}
  </Spin>
  );
};

export default AdminLayout;
