const FieldReference = Object.freeze({
  Integer: "11",
  ID: "13",
  Date: "15",
  Text: "14",
  String: "10",
  List: "17",
  DateTime: "16",
  Table: "18",
  Amount: "12",
  Number: "22",
  Binary: "23",
  Time: "24",
  RowID: "26",
  Color: "27",
  Button: "28",
  YesNo: "20",
  TableDir: "19",
  Quantity: "29",
  Assignment: "33",
  Search: "30",
  Image: "32",
  Memo: "34",
  Price: "800008",
  PAttribute: "35",
  GeneralQuantity: "800019",
  Link: "800101",
  AbsoluteTime: "20D7C424C217463F914DF632E5CF5923",
  DateTime_From: "487AE3E73E8542538CDC4A9EED5F6434",
  DateTime_To: "439F775E24BF48EDA51A7AE2535F0DCE",
  AbsoluteDateTime: "478169542A1747BD942DD70C8B45089C",
  Password: "16EC6DF4A59747749FDF256B7FBBB058",
  ImageBLOB: "4AA6C3BE9D3B4D84A3B80489505A23E5",
  TreeReference: "8C57A4A2E05F4261A1FADF47C30398AD",
  ProductCharacteristics: "C632F1CFF5A1453EB28BDF44A70478F8",
  PasswordNotDecryptable: "C5C21C28B39E4683A91779F16C112E40",
  ButtonList: "FF80818132F94B500132F9575619000A",
  RichTextArea: "7CB371C13D204EB69BF370217F692999",
  MaskedString: "52529102938F41D5B84D3DE1F8892249",
  OBUISEL_SelectorAsLinkReference: "80B1630792EA46F298A3FBF81E77EF9C",
  OBUISEL_MultiSelectorReference: "87E6CFF8F71548AFA33F181C317970B5",
  OBUISEL_SelectorReference: "95E2A8B50A254B2AAE6774B8C2F28120",
  OBKMO_WidgetinFormReference: "FF8080813123BF670131241C2BB30012",
  WindowReference: "FF80818132D8F0F30132D9BC395D0038",
  JSON: "1CD701C19B53450CA608F7FB55F5EA1F",
  WYSIWYGEditor: "176912FFCB4D4D01ADA0E72A2EEAE598",
  HTMLEditor: "1571D9E7F22D49F380B74DD11AF44AE9",
});

export { FieldReference };