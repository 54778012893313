/* eslint-disable */

import React from 'react'
import * as c3 from 'c3';
import 'c3/c3.css';
import './index.css';


const BarChart=(props)=> {
    const {barChartdata,barChartProperties,fullScreenValue,uniqueIndex,colSpace}=props
    /* const kpiId = element.kpi_id;
    const drillDownInputFilter = element.drilldown_inputfilters;
    const dashboardId = element.nt_dashboard_id;
    const drillDownKpiId = element.drilldown_kpi_id;
    const drillDownKpiTitle = element.title;
    const widgetHeight = element.widget_height;
    const colSpace = element.column_space;
    const barChartdata = hideChartData === "Y" ? [] : dashboardDataInState[element.kpi_id];
    const barChartProperties = JSON.parse(element.properties); */
    const firstTenElementsArray = [];
    let maxValue=0;
    let maxValue2=0;

    const responsiveDesignForColumn = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 24,
      sm: 24,
      md: 12,
    };

    if (barChartdata !== undefined) {      
      barChartdata[1].map((tre,ind)=>{
        if(ind !== 0){
           if(maxValue<parseInt(tre)){
            maxValue=parseInt(tre)
           }
        }
      })

      barChartdata[2]?.map((rew,index)=>{
        if(index !== 0){
           if(maxValue2<parseInt(rew)){
            maxValue2=parseInt(rew)
           }
        }
      })
      for (let index = 0; index < barChartdata?.length; index += 1) {
        const element = barChartdata[index];
        firstTenElementsArray.push(element.slice(0, 11));
        // firstTenElementsArray.push(element)
      }


      const chart = c3.generate({
        bindto: `#chart${uniqueIndex}`,
        data: {
          x: "x",
          columns: fullScreenValue === false ? firstTenElementsArray.slice(0, 3) : barChartdata,
          type: "bar",
          colors: {
            // pattern: [barChartProperties['barColor']],
            "Current Year": "#84B3FD",
            "Previous Year": "#FFB45C",
            [barChartProperties["X"]]: [barChartProperties["barColor"]],
          },
          color: function (color, d) {
            return color;
          },
        },
        legend: {
          position: "inset",
          inset:{
            anchor:'top-right',
            x:-1,
            y:-15,
            step:1
          },
        },

        bar: {
          width: 16,
          space: 0.15,
          // spacing: 9
        },
        size: {
          height:238
        },
        axis: {
          x: {
            type: "categorized", // this needed to load string x value
            tick: {
              fit:true,
              multiline: true,
              multilineMax: 2
              // multiline: true,
              // multilineMax: 2,
              // multilineMax: 1,
            },
            // height: 80,
          },
          y: {
            
            tick: {
              format: function (d) {
                if (d / 1000000000 >= 1) {
                  return +(d / 1000000000).toFixed(1) + " B";
                } else if (d / 1000000 >= 1) {
                  return +(d / 1000000).toFixed(1) + " M";
                } else if (d / 1000 >= 1) {
                  return +(d / 1000).toFixed(1) + " K";
                } else {
                  return d;
                }
              },
            },
            label: {
              // text: barChartProperties["Y"],
              position: 'outer-middle'
            },
          },
          y2:{
            show:true,
          }
        },
        grid: {
          y: {
              lines: [
                  {value: maxValue >maxValue2?maxValue/4:maxValue2/4,class:'grid8'},
                  {value: maxValue>maxValue2?maxValue*(3/4):maxValue2*(3/4),class:'grid8'},
                  {value: maxValue>maxValue2?maxValue/2:maxValue2/2,class:'grid8'},
                  {value: maxValue>maxValue2?maxValue:maxValue2,class:'grid8'},
                  // {value: 3900,class:'grid8'},
                  // {value: 6900,class:'grid8'}
              ]
          }
      }
      });
      // window.innerWidth <= 2634 ? chart.resize({ height: 220, width: 600 }) :''
      // window.innerWidth <= 2000 ? chart.resize({ height: 230, width: 400 }) :''
      // window.innerWidth <= 1317 ? chart.resize({ height: 200, width: 400 }) :''
      fullScreenValue === true ? chart.resize({ height: 480, width: 1080 }) : "";
    }
    return (
      <>
        <div style={{paddingTop:'2%',right:15}} id={`chart${uniqueIndex}`} />
      </>
    );
}

export default BarChart
