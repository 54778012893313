import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Layout, Card,List, Select, Popover, Row, Col, Drawer, Button, Tooltip } from "antd";
import sideIcon from "../../assets/images/sideNavIcon.svg"
import { useGlobalContext } from "../../lib/storage";
import {
  getAdminMenuListForDynamicLogos,
} from "../../services/generic";

import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";
import "./menu.css";
import Scrollbars from "react-custom-scrollbars";

const { Sider } = Layout;
const { SubMenu } = Menu;

const toggleFullHideButtonInactive = {
  position: "absolute",
  bottom: "-4px",
  backgroundColor: "#FFFFFF",
  color: "white",
  height: "30px",
  width: "35px",
  borderRadius: "0px 50px 50px 0px",
  textAlign: "center",
  left: "59px",
  zIndex: "9",
  fontWeight: "bold",
  cursor: "pointer",
  padding: "5px",
};

const toggleFullHideButtonActive = {
  ...toggleFullHideButtonInactive,
  left: "0px",
};

const SideNav = (props) => {
  // const textInput = React.useRef(null);

  const { globalStore, setGlobalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const { sideMenuData: menuList, windowTabs, userPreferences } = globalStore;
  const { menuToggle,collapsed, setMenuToggle, showExpandedMenu,setShowExpandedMenu, setFullMenuToggle } = props;
  const [menuData, setMenuData] = useState([]);
  const history = useHistory();
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);

  let subMenuData =JSON.parse(localStorage.getItem("subMenuData"))
  const [openKeys, setOpenKeys] = useState(() => {
    // Set the initial state of openKeys using a function
    const subMenuData = JSON.parse(localStorage.getItem("subMenuData"));
    return subMenuData.map((menu) => menu.key);
  });

  useEffect(() => {
    const newOpenKeys = subMenuData.map((menu) => menu.key);
    // Only update openKeys if it has changed to avoid an infinite loop
    if (JSON.stringify(openKeys) !== JSON.stringify(newOpenKeys)) {
      setOpenKeys(newOpenKeys);
    }
  }, [subMenuData]);
  
  const toggleExpandedMenu = () => {
    setShowExpandedMenu(!showExpandedMenu);
  };

  useEffect(() => {
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
            item.children.map((subItem, subIndex) => {
              if (subItem.children && subItem.children.length > 0) {
                subItem.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
              }
              // item.children[subIndex] = subItem;
              return null;
            });
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }

  }, [menuList]);

  const visibleItems = menuData.slice(0,7); 
  const hiddenItems = menuData.slice(7); 

  const content = (<div
    // onMouseEnter={()=>{setShowExpandedMenu(!showExpandedMenu)}}
    // onMouseLeave={()=>{setShowExpandedMenu(!showExpandedMenu)}}
    style={{
      // position: "absolute",
      overflow:"hidden",
      bottom: 0,
      left: "calc(4.7em)",  // Adjust the value based on your list width and margin
      backgroundColor: "#fff",
      // border: "1px solid #ccc",
      boxShadow:'0px 0px 10px 1px rgba(0, 0, 0, 0.04)',
      zIndex: 1,  // Adjust the z-index as needed
      display: "flex",
      flexDirection: "column",
      width: "14em",  // Adjust the width as needed
    }}
    >
      <Row gutter={[8, 8]} style={{ padding: "1em" }}>
      {hiddenItems.map((menu) => (
        <Col
          span={12}
          onClick={() => handleListClick(menu.key, menu)}
          onMouseEnter={() => setHoveredMenuItem(menu.key)}
          onMouseLeave={() => setHoveredMenuItem(null)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            paddingTop: "0.7em",paddingBottom:"0.7em",paddingLeft:"0.1em",paddingRight:"0.1em",
            backgroundColor: hoveredMenuItem === menu.key ? "#dadada" : "transparent",
          }}
          key={menu.key}
        >
          <Tooltip placement="top" title={menu.title}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span
                className={menu.icon}
                style={{ color: "#6277B8", fontSize: "1.4em", marginBottom: "0.1em" }}
              ></span>
              <p
                style={{
                  fontSize: "9px",
                  marginBottom: 0,
                  fontWeight: 500,
                  fontFamily: "Inter",
                  textAlign: "center",
                  color: "#6277B8",
                }}
              >
                {menu.title}
              </p>
            </div>
          </Tooltip>
        </Col>
      ))}
    </Row>
    </div>)
  
 let selectecMianMenuItem = JSON.parse(localStorage.getItem("selectecMianMenuItem"))
  let lowerSubMenuItem =JSON.parse(localStorage.getItem("lowerSubMenuItem"))
  let subMenuItem = JSON.parse(localStorage.getItem("subMenuItem"))
  const handleListClick = (menuKey, menu) => {
    setShowExpandedMenu(false)
    menuData.map((menu, index) => {
      if (menu.key === menuKey) {
        localStorage.setItem("selectecMianMenuItem", JSON.stringify(menuKey))
        localStorage.setItem("subMenuData", JSON.stringify(menu.children))
        // setSelectedMainMenuItem(menu.children)
      }
    })
    navigateToFirstChild({ type: menu.children[0].type, id: menu.children[0].id, key: menu.children[0].key, children: menu.children[0].children})
  }

  

  const navigateToFirstChild = (data) => {
    if (data.children === undefined || data.children === null) {
      localStorage.removeItem('lowerSubMenu')
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      localStorage.setItem("lowerSubMenuItem", null)
      // setMenuToggle(true)
      // setFullMenuToggle(true)
      if (data.type === "Report") {
        history.push(`/reports/report/${data.id}`);
      } else if (data.type === "Dashboard") {
        localStorage.setItem("windowType","Dashboard")
        history.push(`/analytics/dashboard/${data.id}`);
      } else if (data.type === "Generic") {
        history.push(`/window/list/${data.id}`);
      } else if (data.type === "Custom") {
        history.push(`/others/window/${data.id}`);
      }
    }
    else {
      // setMenuToggle(false)
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      localStorage.setItem("lowerSubMenu", JSON.stringify(data.children))
      localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.children[0].key))
      if (data.children[0].type === "Report") {
        localStorage.setItem("windowType","Report")
        history.push(`/reports/report/${data.children[0].id}`);
      } else if (data.children[0].type === "Dashboard") {
        localStorage.setItem("windowType","Dashboard")
        history.push(`/analytics/dashboard/${data.children[0].id}`);
      } else if (data.children[0].type === "Generic") {
        history.push(`/window/list/${data.children[0].id}`);
      } else if (data.children[0].type === "Custom") {
        history.push(`/others/window/${data.children[0].id}`);
      }

    }

  }


  let sideMenuStyle = ""
  let rightMargin = ""
  if(menuToggle){
    sideMenuStyle = "0px"
    rightMargin="23px"
  }else{
    sideMenuStyle = "15px"
    rightMargin="0px"
  }

  return (
    <div  className="responsiveSideMenu" style={{height:"",marginRight:'',fontFamily:'Inter'}}>
  <>
      <Sider collapsedWidth={collapsed?0:75} width={collapsed?0:75} className="third" trigger={null} collapsed={!collapsed} style={{ transition: 'transform 0.3s', transform: collapsed ? 'translateX(-100%)' : 'none' }}>
        <div style={{display:"flex"}}>
      <List className="iconMenu" split={false} style={{ display: "inline-block",backgroundColor:"#0C173AE5", width: "5.3em",height:'100vh' }}>
      <List.Item style={{cursor:"pointer"}} onClick={()=>{history.push("/myApps")}}>
          <span style={{padding:"0 1.8em" }}>
          <img src={sideIcon} alt=""/>
          </span>
        </List.Item>
          {visibleItems.map(menu => (
            <List.Item onMouseEnter={() => setHoveredMenuItem(menu.key)}
            onMouseLeave={() => setHoveredMenuItem(null)} onClick={() => handleListClick(menu.key, menu)}  style={{height:"3.8em",cursor:"pointer",margin:"0.5em 0.55em 0em 0.55em",backgroundColor: selectecMianMenuItem === menu.key || hoveredMenuItem === menu.key ? "#334170" : "transparent",textAlign:"center",borderRadius:"8px"}} key={menu.key}>
              <Tooltip placement="right"  title={menu.title}>
                <span
                  className={menu.icon}
                  style={{  color: selectecMianMenuItem === menu.key ? "#FEFEFE" : hoveredMenuItem === menu.key ? "#e4eafa":"#6277B8",fontSize:"1.2em",padding:"0 1.2em" }}
                >
                </span>
                <p style={{fontSize:"9px",marginBottom:0,marginTop:"0.1em",lineHeight:"10px",fontWeight:500,fontFamily:"Inter",textAlign:"center",color:selectecMianMenuItem === menu.key ? "#FEFEFE":"#6277B8"}}>{menu.title}</p>
              </Tooltip>
            </List.Item>
          ))}
           {hiddenItems.length > 0 && (
        <List.Item
        // onClick={toggleExpandedMenu}
          style={{ cursor: "pointer", margin: "0.5em 1px 0.5em 0em", backgroundColor: "transparent" }}
          key="ellipsis"
        >
          <Tooltip placement="right" title="More">
          <Popover content={content} placement="right">
            <span
              className="fa fa-ellipsis-h" 
              style={{ color: "#6277B8", fontSize: "1.5em", padding:"0 1.4em" }}
            >
              
            </span>
            </Popover>
          </Tooltip>
        </List.Item>
      )}
        </List>
              </div>
       </Sider>
       </>
       
    </div>
  );
};

export default SideNav;
