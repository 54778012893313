import React, { useState, useEffect, Fragment } from "react";
import { Card, Row, Col, Button, Collapse,Select, Form,Modal,Menu, Typography, Spin, message, notification, Dropdown, Tooltip } from "antd";
import { LoadingOutlined,CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";

import { getTabData, upsertTabData, getWindowInitializationData } from "../../services/generic";
import { FieldReference } from "../../lib/fieldReference";

import Edit from "../../assets/images/edit.svg";
import SaveMobile from "../../assets/images/saveForm.svg";

import Repeat from "../../assets/images/repeat.svg";
import Print from "./Print";
import EmailTemplate from "./EmailTemplate";
import FileAttachment from "./FileAttachment";
import dayjs from "dayjs";
import { useGlobalContext, useWindowContext } from "../../lib/storage";
import { formatDisplayField } from "../window/windowUtilities";
import "antd/dist/antd.css";
import "./style.css"

const { Panel } = Collapse;
const { Text } = Typography;
const { SubMenu } = Menu;
const { Option,OptGroup } = Select;

const customParseFormat = require("dayjs/plugin/customParseFormat");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const RecordFooter = (props) => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const { lastRefreshed,isEditMode,setIsEditMode, setLastRefreshed, setHeaderRecordData,isHeaderActive,headerRecordData,setFormFlag, setIsHeaderActive, setLoadingRecordWindow } = props;
  const { recordId } = useParams();
  const [headerTab, setHeaderTab] = useState({ fields: [] });
  const [headerTabId, setHeaderTabId] = useState("");
  const [headerRecord, setHeaderRecord] = useState({});
  const [headerFieldGroups, setHeaderFieldGroups] = useState({});
  const [headerReferenceList, setHeaderReferenceList] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [mobMenu,setMobMenu] = useState(false)
  const [recordTitles, setRecordTitles] = useState([]);
  const [statusBar, setStatusBar] = useState([]);
  
  const [isActive,setIsActive] = useState(false)
  const [loading, setLoading] = useState(false);
  const [displayAuditInfo, setDisplayAuditInfo] = useState(false);
  const [auditData, setAuditData] = useState({});
  const [autoCompleteHiddenData,setAutoCompleteHiddenData] = useState([])
  const [recordTitle1,setRecordTitle1] = useState()

  const history = useHistory();

  const { windowStore, setWindowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };

  
  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        setLoading(true);
        try {
          if (windowDefinition.tabs) {
            const headerTabData = windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
            const headerTabId = headerTabData.ad_tab_id;
            headerTabData.fields.sort((a, b) => {
              const x = a.seqno !== null ? parseInt(a.seqno) : a.seqno;
              const y = b.seqno !== null ? parseInt(b.seqno) : b.seqno;
              return (x != null ? x : Infinity) - (y != null ? y : Infinity);
            });
            setHeaderTab(headerTabData);
            setHeaderTabId(headerTabId);

            let headerRecordData;
            if (recordId !== "NEW_RECORD") {
              const getTabDataResponse = await getTabData({ ad_tab_id: headerTabData.ad_tab_id, recordId: recordId, startRow: "0", endRow: "1" });
              headerRecordData = getTabDataResponse[0];
            } else {
              setIsEditMode(true);
              headerRecordData = await getWindowInitializationData(headerTabData.ad_tab_id);
            }
            setHeaderRecord(headerRecordData);
            setHeaderRecordData({ ...headerRecordData });
            setWindowStore({ windowHeaderTabRecords: headerRecordData });

            const recordTitle = headerTabData.record_identifier.split(",");
            const recordTitlesData = [];
            recordTitle.forEach((element) => {
              const headerFieldIndex = headerTabData.fields.findIndex((field) => field.ad_field_id === element);
              const recordIdentifierField = headerTabData.fields[headerFieldIndex];
              let titleNameValue = headerRecordData[element.concat("_iden")];
              if (titleNameValue === null || titleNameValue === undefined) {
                titleNameValue = headerRecordData[element];
              }
              titleNameValue = formatDisplayField(titleNameValue, recordIdentifierField);
              recordTitlesData.push({
                titleName: titleNameValue,
                titleTip: titleNameValue,
              });
             
            });
            setRecordTitles([...recordTitlesData]);
            

            const statusBarValues = [];
            const referenceList = [];
            const fieldGroupsList = {};
            const auditDataValues = {};
            headerTabData.fields.forEach((element) => {
              if (element["nt_base_reference_id"] === FieldReference.List) {
                const list = element.Values;
                if (list !== undefined || list !== null) {
                  Object.keys(list).forEach((key) => {
                    referenceList.push(list[key]);
                  });
                }
              }

              if (element.isshowninstatusbar === "Y" && element.isdisplayed === "Y" && element.isactive === "Y") {
                let titleDataValue = headerRecordData[element.ad_field_id.concat("_iden")];
                if (titleDataValue === null || titleDataValue === undefined) {
                  titleDataValue = headerRecordData[element.ad_field_id];
                  const refIndex = referenceList.findIndex((list) => list.key === titleDataValue);
                  if (refIndex >= 0) {
                    titleDataValue = referenceList[refIndex].value;
                  }
                }
                if (titleDataValue === "Y") {
                  titleDataValue = "Yes";
                }
                if (titleDataValue === "N") {
                  titleDataValue = "No";
                }
                statusBarValues.push({
                  titleName: element.name,
                  amountId:element.nt_base_reference_id,
                  titleValue: titleDataValue,
                  ad_field_id:element.ad_field_id

                });
                // setRecordTitle1(element.name)
              }

              if (element.fieldgroup_name !== undefined && element.nt_base_reference_id !== "28") {
                fieldGroupsList[element.fieldgroup_name] = fieldGroupsList[element.fieldgroup_name] || [];
                fieldGroupsList[element.fieldgroup_name].push(element);
              }

              if (element.column_name?.toLowerCase() === "updatedby") {
                auditDataValues.updatedby = headerRecordData[element.ad_field_id.concat("_iden")];
              }
              if (element.column_name?.toLowerCase() === "createdby") {
                auditDataValues.createdby = headerRecordData[element.ad_field_id.concat("_iden")];
              }
              if (element.column_name?.toLowerCase() === "created") {
                auditDataValues.created = dayjs(headerRecordData[element.ad_field_id], "YYYY-MM-DD HH:mm:ss").fromNow();
              }
              if (element.column_name?.toLowerCase() === "updated") {
                auditDataValues.updated = dayjs(headerRecordData[element.ad_field_id], "YYYY-MM-DD HH:mm:ss").fromNow();
              }
            });
            setStatusBar([...statusBarValues]);
            setHeaderFieldGroups(fieldGroupsList);
            setHeaderReferenceList([...referenceList]);
            setAuditData({ ...auditDataValues });
          }
        } catch (error) {
          console.error("Error", error);
        } finally {
          setLoading(false);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [recordId, lastRefreshed]);

  useEffect(() => {
    if(autoCompleteHiddenData.length > 0){
    for (let index1 = 0; index1 < statusBar.length; index1++) {
      const element1 = statusBar[index1].ad_field_id;
      for (let index = 0; index < autoCompleteHiddenData.length; index++) {
        const element2 = autoCompleteHiddenData[index].dataKey;
        if(element1 === element2){
          statusBar[index1].titleValue = autoCompleteHiddenData[index].dataValName  === "Y" ? "Yes" : autoCompleteHiddenData[index].dataValName === "N" ? "NO" : autoCompleteHiddenData[index].dataValName
          statusBar[index1].recordValue = autoCompleteHiddenData[index].dataValKey

        }
      }
      setStatusBar([...statusBar]);
    }
  }}    
  , [autoCompleteHiddenData]);

  const updateLastRefresh = () => {
    setLastRefreshed(new Date());
  };

  const getRecordValue = (field) => {
    let recordValueField = headerRecord[field.ad_field_id.concat("_iden")] ? headerRecord[field.ad_field_id.concat("_iden")] : headerRecord[field.ad_field_id];
    const refIndex = headerReferenceList.findIndex((list) => list.key === recordValueField);
    if (refIndex >= 0) {
      recordValueField = headerReferenceList[refIndex].value;
    }
    // if (typeof recordValueField === "string") {
    //   if (recordValueField.trim() === "Y") {
    //     recordValueField = "Yes";
    //   }
    //   if (recordValueField.trim() === "N") {
    //     recordValueField = "No";
    //   }
    // }
    return formatDisplayField(recordValueField, field, "header");
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };



  useEffect(() => {
    setIsHeaderActive(isEditMode);
  }, [isEditMode]);



  const [collapseAllGroups, setCollapseAllGroups] = useState(false);

  const [form] = Form.useForm();


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }


  // const innerWidth1 = windowSize.innerWidth
  let subMenuItem = JSON.parse(localStorage.getItem("subMenuItem"))
  let subMenuData =JSON.parse(localStorage.getItem("subMenuData"))
  // const innerWidth = window.innerWidth;

  const innerWidth = windowSize.innerWidth;

 

  let recordTitleDataCheck = []
  let recordTitle ;
  for (let index = 0; index < recordTitles.length; index+=1) {
    
    if(recordTitles[index].titleName !== undefined){
      recordTitleDataCheck.push(recordTitles[index])
      recordTitle = recordTitles[0].titleName
    }
    // setRecordTitle1()
  }
  
  return (
   <>
   <Row className="uuu" style={{ padding: "0 2em" }}>
   <Col span={24} >
   <div style={{overflowX:"scroll"}} >
     <div style={{ display: 'flex',justifyContent:"space-between" }}>
              {windowDefinition.enableedit === "Y" && !isEditMode ? (
                <span>
                    <Tooltip title="Edit" placement="top">
                    <div onClick={handleEdit} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column',minWidth:"20%" }}>
                        <img style={{ cursor: 'pointer' }} src={Edit} alt="invoice" />
                        <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#192228' }}>Edit</p>
                        </div>
                    </Tooltip>
                  {/* <Button style={{fontFamily:"Roboto",fontWeight:"700",width:"88px",border:"0.001px solid #DDDBDA",color:"#0C173A",borderRadius:"4px",marginLeft:"4px"}} onClick={handleEdit}>
                    Edit
                  </Button> */}
                  {/* <Button style={{fontFamily:"Roboto",fontWeight:"700",width:"88px",border:"0.001px solid #DDDBDA",color:"#0C173A",borderRadius:"4px" }} onClick={handleCancel}>
                    Close
                  </Button> */}
                </span>
              ) : (
                ""
              )}{" "}
              {isEditMode ? (
                <span>
                  <Tooltip title="Save" placement="top">
                    <div  onClick={() => { setFormFlag(true) }}
                     style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column',minWidth:"20%" }}>
                        <img style={{ cursor: 'pointer' }} src={SaveMobile} alt="invoice" />
                        <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#192228' }}>Save</p>
                        </div>
                    </Tooltip>
                  {/* <Button style={{fontFamily:"Roboto",fontWeight:"700",width:"88px",border:"0.001px solid #DDDBDA",color:"#0C173A",borderRadius:"4px",marginLeft:"4px"}} onClick={handleCancel}>
                    Cancel
                  </Button> */}
                </span>
              ) : (
                ""
              )}
               {windowDefinition.enableprint === "Y" ? <Print setLoadingRecordWindow={setLoadingRecordWindow} {...props} headerTabId={headerTabId} innerWidth={innerWidth} /> : null}
               {windowDefinition.enableprint === "Y" ? <EmailTemplate headerTabId={headerTabId} innerWidth={innerWidth} /> : null}
                      <Tooltip placement="top" title="Repeat">
                        {/* <Button style={Themes.contentWindow.ListWindowHeader.headerActionButtons} onClick={updateLastRefresh}>
                          <img style={{ paddingBottom: "3px", paddingRight: "2px", width: "18px" }} src={Repeat} alt="invoice" />
                        </Button> */}
                         <div onClick={updateLastRefresh} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                        <img style={{ cursor: 'pointer' }} src={Repeat} alt="invoice" />
                        <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#192228' }}>Reload</p>
                        </div>
                      </Tooltip>

                      {windowDefinition.enableattachment === "Y" || headerTab.tabenabledforimport === "Y" ? (
                        <FileAttachment style={{ marginRight: "4px" }} headerTabId={headerTabId} />
                      ) : (
                        ""
                      )}
              </div>
              </div>
              </Col>
              </Row>
   </>
  );
};

export default RecordFooter;