import React from "react";
import { useParams } from "react-router";
import { Button,Row , Col , notification, Tooltip } from "antd";
import { getPrintTemplate, getPrintDownloadData } from "../../services/generic";
import { useGlobalContext } from "../../lib/storage";
import PrintIcon from "../../assets/images/print.svg";
import PrintIconNew from "../../assets/images/printNew.svg";
import "./style.css";
import { DOWN_lOAD_TYPE } from "../../constants/serverConfig";

const Print = (props) => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const { headerTabId, setLoadingRecordWindow } = props;
  const { recordId } = useParams();

  const responsiveCol = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 0,
    sm: 0,
    md: 24,
  }
const responsiveMobile = {
  xxl: 0,
  xl: 0,
  lg: 0,
  xs: 24,
  sm: 24,
  md: 0,
}
  const printTemplate = async () => {
    try {
      setLoadingRecordWindow(true);
      const printTemplateData = await getPrintTemplate(headerTabId, recordId);
      if (printTemplateData.data.data.reportTemplate === null || printTemplateData.data.data.reportTemplate === "null" || printTemplateData.data.data.reportTemplate === "") {
        notification.info({
          message: "File Not Found..!!!!",
        });
        setLoadingRecordWindow(false);
      } else {
        getPrintCommand(printTemplateData.data.data.reportTemplate);
      }
    } catch (error) {
      console.error("Error", error);
      setLoadingRecordWindow(false);
    }
  };

  const getPrintCommand = async (fileName) => {
    if(DOWN_lOAD_TYPE ==="S3"){
      const fileURL = fileName;
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `${fileName}`);
      link.click();
      setLoadingRecordWindow(false);
    }else{
      const downloadPrintData = await getPrintDownloadData(fileName);
    const fileURL = window.URL.createObjectURL(new Blob([downloadPrintData.data]));
    const link = document.createElement("a");
    link.setAttribute("id", "downloadlink");
    link.href = fileURL;
    link.setAttribute("download", `${fileName}`);
    link.click();
    setLoadingRecordWindow(false);
    }
  };

  const listActionButtons = {
    height: "32px",
    width: "30px",
    background: "#fff",
    border: "0.5px solid #d9d9d9",
    cursor: "pointer",
    marginRight: "5px ",
    // display:"flex",
    justifyContent:"center",
    alignItems:"center",
    description: "list icon buttons on the right hand side"
  }

  return (
    <Row>
      <Col {...responsiveCol}>
      <Tooltip  placement="top" title="Print">
      <Button className="listHeaderButtons" style={listActionButtons}>
        <img onClick={printTemplate} style={{cursor:"pointer",marginLeft:"-0.65em",marginBottom:"5px"}} src={PrintIconNew} alt="invoice" /> &nbsp;
        </Button>
    </Tooltip>
      </Col>
      <Col {...responsiveMobile}>
      <Tooltip placement="top" title="Print">
      <div onClick={printTemplate} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column',minWidth:"20%" }}>
        <img style={{ cursor: 'pointer' }} src={PrintIcon} alt="invoice" />
        <p style={{ fontFamily: 'Inter', fontWeight: 400, color: '#192228',marginTop:"-0.3em" }}>Print</p>
      </div>
    </Tooltip>
      </Col>
    </Row>
  );
};

export default Print;
